.form-legend {
  background-color: $grey-lightest;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
  font: 500 0.875rem/1.25rem $font;
  color: $grey-dark;

  p {
    margin-top: 1.25rem;

    &:first-of-type {
      margin-top: 0;
    }
  }

  .list {
    margin-top: 0.5rem;
  }

  &.form-legend-has-elements {
    padding-bottom: 0;
  }

  &.form-legend-button {
    .button {
      margin-top: 1rem;

      @supports (width: max-content) {
        width: max-content;
      }

      &.button-secondary {
        border: none;
      }
    }
  }
}

@media only screen and (min-width: 720px) {
  .form-legend {
    &.form-legend-button {
      @include flex-space-between;

      p {
        margin: calculate-rem(13px) 0.5rem calculate-rem(9px) 0.5rem;
        max-width: 27.5rem;
      }

      .button {
        margin-top: 0;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .form-legend {
    padding: 1rem 1.25rem;
  }
}
