@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
$font: "Poppins", sans-serif;

body {
  font: 400 0.875rem/1.625rem $font;
  letter-spacing: 0.4px;
}

.heading-extra-large {
  font: 600 1.375rem/2rem $font;
  color: $grey-darkest;
  letter-spacing: 0.2px;
}

.heading-large {
  font: 600 1.375rem/2rem $font;
  color: $grey-darkest;
  letter-spacing: 0.2px;
}

.heading-medium {
  font: 600 calculate-rem(21px) / 1.75rem $font;
  color: $grey-darkest;
  letter-spacing: 0.2px;
}

.heading-small {
  font: 600 1.125rem/1.625rem $font;
  color: $grey-darkest;
  letter-spacing: 0.4px;
}

.heading-extra-small {
  font: 600 0.875rem / calculate-rem(21px) $font;
  color: $grey-darkest;
  letter-spacing: 0.4px;
}

p {
  color: $grey-dark;
}

@media only screen and (min-width: 700px) {
  .heading-extra-large {
    font-size: 1.625rem;
    line-height: 2.25rem;
    letter-spacing: 0.4px;
  }

  .heading-large {
    font-size: calculate-rem(23px);
  }
}

@media only screen and (min-width: 1100px) {
  body {
    font-size: 1rem;
    line-height: 1.875rem;
  }

  .heading-extra-small {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
