@use "sass:math";
$unit: 0.25;

// Margin Top

.mt-1 {
  margin-top: #{$unit * 1}rem;
}

.mt-2 {
  margin-top: #{$unit * 2}rem;
}

.mt-3 {
  margin-top: #{$unit * 3}rem;
}

.mt-4 {
  margin-top: #{$unit * 4}rem;
}

.mt-5 {
  margin-top: #{$unit * 5}rem;
}

.mt-6 {
  margin-top: #{$unit * 6}rem;
}

.mt-7 {
  margin-top: #{$unit * 7}rem;
}

.mt-8 {
  margin-top: #{$unit * 8}rem;
}

// Margin Bottom

.mb-1 {
  margin-bottom: #{$unit * 1}rem;
}

.mb-2 {
  margin-bottom: #{$unit * 2}rem;
}

.mb-3 {
  margin-bottom: #{$unit * 3}rem;
}

.mb-4 {
  margin-bottom: #{$unit * 4}rem;
}

.mb-5 {
  margin-bottom: #{$unit * 5}rem;
}

.mb-6 {
  margin-bottom: #{$unit * 6}rem;
}

.mb-7 {
  margin-bottom: #{$unit * 7}rem;
}

.mb-8 {
  margin-bottom: #{$unit * 8}rem;
}
