@media only screen and (min-width: 720px) {
  .form-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 2.5rem;
    border-top: 1px solid $grey-lighter;
    padding: 2rem 0 0.5rem 0;

    &:last-of-type {
      padding-bottom: 0;
    }

    &:first-of-type {
      padding-top: 0;
      border-top: none;
    }
  }
}
