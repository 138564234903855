.input-result-form {
  @include animation-preset;
  background-color: white;
  border-radius: 0.75rem;
  padding: 2rem 1rem;
  position: relative;
  bottom: 0;
  will-change: bottom;
  transition: bottom 200ms ease;

  @media (hover: hover) {
    &:hover {
      bottom: 0.25rem;
    }
  }

  .input-result-form-name {
    display: block;
    font: 500 1.125rem/1.5rem $font;
    color: $grey-dark;
    text-align: center;
  }
}

@media only screen and (min-width: 900px) {
  .input-result-form {
    padding: 4rem 1.5rem;
    height: 212px;

    .input-result-form-name {
      font-size: calculate-rem(21px);
      line-height: 1.75rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% - 3rem);
    }
  }
}
