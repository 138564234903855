.button-link-with-icon {
  display: grid;
  align-items: center;
  gap: 0.25rem;
  padding: 0.25rem 0.5rem 0.25rem 0.25rem;
  will-change: background-color;
  border-radius: 0.5rem;

  @supports (width: max-content) {
    width: max-content;
  }

  @media (hover: hover) {
    &:hover {
      background-color: #e8e8eb;
    }
  }

  &.button-link-with-icon-left {
    grid-template-columns: 1.5rem 1fr;
  }

  &.button-link-with-icon-right {
    grid-template-columns: 1fr 1.5rem;
  }

  .button-link-with-icon-icon {
    font-size: 1.5rem;
    color: $grey-medium;
  }

  .button-link-with-icon-text {
    font: 500 1rem/1.5rem $font;
    color: $grey-dark;
  }
}
