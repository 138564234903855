@import "./ResultFormButton/result-form-button";

.result-forms {
  max-width: 1248px;
  margin: 0 auto;

  .result-form-group {
    .result-form-group-heading {
      margin-bottom: 0.75rem;
    }

    .result-form-group-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1.5rem;
    }
  }
}

@media only screen and (min-width: 900px) {
  .result-forms {
    padding-top: 0.5rem;

    .result-form-group {
      .result-form-group-heading {
        margin-bottom: 1.25rem;
      }

      .result-form-group-list {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 2rem;
      }
    }
  }
}
