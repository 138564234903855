@keyframes loaderSpin {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

.button {
  @include animation-preset;
  @include button-preset;
  display: block;
  cursor: pointer;
  font: 600 0.875rem/1.25rem $font;
  letter-spacing: 0.4px;
  padding: 0.75rem 1rem;
  text-decoration: none;
  text-align: center;
  border-radius: 0.5rem;

  &.button-loading {
    &:before {
      @include animation-preset;
      display: block;
      content: "";
      border-radius: 50%;
      width: 1.25rem;
      height: 1.25rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0) rotate(45deg);
      animation: loaderSpin 600ms forwards infinite;
    }

    .button-text {
      visibility: hidden;
    }
  }

  &.button-primary {
    background-color: $orange-medium;
    color: white;

    &.button-loading {
      &:before {
        border: white 0.125rem solid;
        border-top: transparent 0.125rem solid;
      }
    }

    @media (hover: hover) {
      &:hover {
        background-color: #f0a330;
      }
    }
  }

  &.button-secondary {
    background-color: white;
    border: 1px solid $grey-lighter;
    padding-top: calculate-rem(11px);
    padding-bottom: calculate-rem(11px);
    font-weight: 500;
    color: $grey-dark;

    &.button-loading {
      &:before {
        border: $grey-medium 0.125rem solid;
        border-top: transparent 0.125rem solid;
      }
    }

    @media (hover: hover) {
      &:hover {
        background-color: $grey-lightest;
      }
    }
  }

  &.button-tertiary {
    background-color: white;
    font-weight: 500;
    color: $grey-dark;
    text-decoration: none;

    @media (hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.button-delete {
    background-color: $red-dark;
    color: white;
  }

  &.button-form-card {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}

@media only screen and (min-width: 1024px) {
  .button {
    font-size: 1rem;
    line-height: 1.5rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}
